import React, { Component } from 'react';
import ninja from "../images/coding-store/codeN.svg"
import element from "../images/coding-store/codeE.png"
import cont from "../images/coding-store/codeC.svg"

class CodingPartners extends Component {
    render(){
        return(
            <div className="CodingPartners">
                <div className="CodingPartners__Header">Classplus Coding Partners</div>
                <div className="CodingPartners__Body">
                    <div className="CodingPartners__Body--Content">
                        <img src={element} alt="coding" className="CodingPartners__Body--Content__Img" />
                        <div className="CodingPartners__Body--Content__Para">Codecademy</div>
                    </div>
                    <div className="CodingPartners__Body--Content">
                        <img src={ninja} alt="coding" className="CodingPartners__Body--Content__Img" />
                        <div className="CodingPartners__Body--Content__Para">Coding Ninjas</div>
                    </div>
                    <div className="CodingPartners__Body--Content">
                        <img src={cont} alt="coding" className="CodingPartners__Body--Content__Img" />
                        <div className="CodingPartners__Body--Content__Para">More coming soon</div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CodingPartners