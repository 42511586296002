import React, { Component } from "react";
import mobile from "../images/coding-store/firstBg.svg";
import sMobile from "../images/coding-store/girl_img-min.png";

class AffiliateHeader extends Component {
  render() {
    return (
      <div className="landing">
        <div className="landing__col3">
          <img className="landing__col3--img" src={sMobile} alt="mobile" />
        </div>
        <div className="landing__col1">
          <p className="landing__col1--para">
          The Coding Store by Classplus
          </p>
          <p className="landing__col1--para2">
          Brings to you coding courses by best International and National brands
          </p>
          <button
            className="landing__col1--btn"
            onClick={this.props.scrollToRegister}
          >
            Register Now
          </button>
        </div>
        {this.props.showFooter ? 
          <div className="landing__footer">
            <button
              className="landing__footer--btn"
              onClick={this.props.scrollToRegister}
            >
              Register Now
            </button>
          </div>
          : ""
        }
      </div>
    );
  }
}

export default AffiliateHeader;
