import React, { Component } from 'react';

class Information extends Component {
    render(){
        return(
            <div className="Information">
                <div className="Information__Main">{this.props.text}</div>
            </div>
        )
    }
}

export default Information;