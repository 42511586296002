import React, { Component } from "react";
import Home from "./components/Home";
import ScrollToTop from "./ScrollToTop";
import "./App.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {getQuery} from "./params";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

class App extends Component {
  constructor() {
    super();
    this.state = {
      load: true,
      showFooter: true,
    };
  }

  componentDidMount() {
    let token = getQuery()["token"]
    localStorage.setItem('token', token)
  }

  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <ScrollToTop>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route render={() => <Redirect to="/" />} />
            </Switch>
          </ScrollToTop>
        </BrowserRouter>
        <ToastContainer
          style={{ fontSize: "1.5rem", marginLeft: "1.2rem" }}
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    );
  }
}

export default App;
