import React, { Component } from 'react';
import Slider from 'react-slick';
import cor1 from "../images/coding-store/cor1.png";
import cor2 from "../images/coding-store/cor2.png";
import cor3 from "../images/coding-store/cor3.png";
import arrow from "../images/coding-store/arrow.svg";

class CodingNews extends Component {
    render(){
        const settings = {
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: false,
			speed: 1000,
			autoplaySpeed: 2000,
			cssEase: 'linear',
			pauseOnHover: true,
            arrows: true,
            ref : (c) => {this.slider = c}
		};
        return(
            <div className="CodingNews">
                <div className="CodingNews__Header">Coding in news</div>
                <Slider {...settings}>
                    <div className="CodingNews__Content">
                        <img src={cor2} className="CodingNews__Content--Img" />
                        <div className="CodingNews__Content--Para">Huge scope of opportunities for software engineers in India</div>
                    </div>
                    <div className="CodingNews__Content">
                        <img src={cor3} className="CodingNews__Content--Img" />
                        <div className="CodingNews__Content--Para">Top 30 desired skills at Google</div>
                    </div>
                    <div className="CodingNews__Content">
                        <img src={cor1} className="CodingNews__Content--Img" />
                        <div className="CodingNews__Content--Para">Government launches National Education Policy 2020</div>
                    </div>
                </Slider>
                <div className="CodingNews__Footer">
                    <img className="CodingNews__Footer--prev" onClick={() => this.slider.slickPrev()} src={arrow} alt="arrow" />
                    <img className="CodingNews__Footer--next" onClick={() => this.slider.slickNext()} src={arrow} alt="arrow" />
                </div>
            </div>
        )
    }
}

export default CodingNews;