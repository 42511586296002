import React, { Component } from 'react';
import check from "../images/coding-store/circle.svg";
import google from "../images/google.png";
import facebook from "../images/facebook.png";
import microsoft from "../images/microsoft.png";
import uber from "../images/uber.png";

class BuyCourses extends Component {
    render(){
        return(
            <div className="BuyCourses">
                <div className="BuyCourses__Header">Why would your students buy coding courses on your app?</div>
                <div className="BuyCourses__Body">
                    <img src={check} className="BuyCourses__Body--Img" alt="check" />
                    <div className="BuyCourses__Body--Para">Access to top courses courses in their own Coaching Institute’s App</div>
                </div>
                <div className="BuyCourses__Body">
                    <img src={check} className="BuyCourses__Body--Img" alt="check" />
                    <div className="BuyCourses__Body--Para">Courses available at heavy discounts</div>
                </div>
                <div className="BuyCourses__Body">
                    <img src={check} className="BuyCourses__Body--Img" alt="check" />
                    <div className="BuyCourses__Body--Para">Students can start the course as per their convenience anytime within 2 months</div>
                </div>
                <div className="BuyCourses__Body">
                    <img src={check} className="BuyCourses__Body--Img" alt="check" />
                    <div className="BuyCourses__Body--Para">Ready for jobs at top companies</div>
                </div>
                <div className="BuyCourses__Body">
                    <div style={{color:"#ffffff"}}>NONNN</div>
                    <div className="BuyCourses__Body--Footer">
                        <img className="BuyCourses__Body--Footer__Img" src={google} alt="icons" />
                        <img className="BuyCourses__Body--Footer__Img" src={facebook} alt="icons" />
                        <img className="BuyCourses__Body--Footer__Img" src={microsoft} alt="icons" />
                        <img className="BuyCourses__Body--Footer__Img" src={uber} alt="icons" />
                    </div>
                </div>
            </div>
        )
    }
}

export default BuyCourses