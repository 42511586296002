import React, { Component } from "react";

class AffiliateDescription extends Component {
  render() {
    return (
      <div className="AffiliateDescription">
        <p className="AffiliateDescription__Para1">
          What is The Coding Store?
        </p>
        <div className="AffiliateDescription__Italic">“Technology k sath sath badd rahi hai computer programmers ki demand”</div>
        <p className="AffiliateDescription__Para2">
        The Coding Store by Classplus provides a variety of high quality coding courses from top international and national brands for your students, in your own Application.
        </p>
        <p className="AffiliateDescription__Para3">
          Register Now and give your students a chance to get high paying jobs.
        </p>
      </div>
    );
  }
}

export default AffiliateDescription;
