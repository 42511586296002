import React, { Component } from 'react';
import check2 from "../images/coding-store/check2.png";
import closeX from "../images/coding-store/closeX.svg";

class ShowcaseCourses extends Component {
    constructor() {
        super();
        this.state = { 
        }
    }

    render() {
        const {isSuccess} = this.props;
        return (
            <div className="ShowcaseCourses">
            {!isSuccess ? 
                <div className="ShowcaseCourses__Container">
                   <div className="ShowcaseCourses__Container--Para1">You would like to showcase coding courses to your students on your application</div>
                   <div className="ShowcaseCourses__Container--Para2">By submitting you will enable Classplus Coding Store on your application and banners/cards/notifications related to coding store would be made available to your students.</div>
                   <button onClick={this.props.submitApi} className="ShowcaseCourses__Container--Btn">Yes, I want coding courses</button>
                   <button onClick={this.props.submitNoApi} className="ShowcaseCourses__Container--Btn2">No, I don't want coding courses</button>
                </div>
             :
            <div className="SuccessMsg__modalContainerSuccess">
                <div className="modalSuccess">
                <div className="modalSuccess--Close"><img 
                    onClick={this.props.modalOtpClose} src={closeX} alt="closeX" /></div>
                <div className="modalSuccess--Header"><img src={check2} alt="check2" /></div>
                <div className="modalSuccess--ThankYou">Thank you!</div>
                <div className="modalSuccess--Enabled">You have enabled Classplus Coding Store on your application. You will get access to all the courses soon.</div>
                <div className="modalSuccess--Team">Someone from our team will get in touch with you soon</div>
                </div>
            </div>}
        </div>
    )
}}

export default ShowcaseCourses;