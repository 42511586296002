import React, { Component } from "react";
import AffiliateHeader from "../components/AffiliateHeader";
import AffiliateDescription from "../components/AffiliateDescription";
import AffiliateWhy from "../components/AffiliateWhy";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import FAQs from "./FAQs";
import { toast } from "react-toastify";
import { baseUrl } from "../config";
import { withRouter } from "react-router-dom";
import Information from "./Information";
import BuyCourses from "./BuyCourses";
import CodingPartners from "./CodingPartners";
import CodingNews from "./CodingNews";
import ContactUs from "./ContactUs";
import ShowcaseCourses from "./ShowcaseCourses";
import {getQuery} from "../params";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOtp: false,
      englishPara :"High demand for Software Developers- Due to digitalization, employment of software developers is projected to grow 22% by 2029.",
      hindiPara : "अब हर बच्चा अच्छी जॉब की तैयारी कर पायेगा |",
      showFooter:false,
      categoryList : [],
      successModal:false,
      isSuccess:false
    };
  }

  componentDidMount() {
    window.onscroll = () => {
      if (document.documentElement.scrollTop > 600) {
        this.setState({ showFooter: true });
      } else {
        this.setState({ showFooter: false });
      }
    };
    // this.categoriesApi();
  }

  SuccessModal = () => {
    this.setState({isSuccess:!this.state.isSuccess})
  }

  submitApi = (type, data) => {
      let payload = {
        categoryList: type == 'no' ? [] : [15]
      }
      fetch(`${baseUrl}/v2/course/codingStore/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token":this.getQuery()["token"],
        },
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((res) => {
          if(res.status === "failure"){
            toast.err(res.message)
          }else{
            this.SuccessModal()
          }
        })
        .catch((err) => toast.error(err.message));
  };

  getQuery = () => {
    let b = window.location.search
      .slice(1)
      .split("&")
      .map((qStr) => qStr.split("="))
      .reduce((acc, inc) => {
        acc[inc[0]] = inc[1];
        return acc;
      }, {});
    return b;
  };

  toggleSuccess = () => {
    this.setState({successModal:!this.state.successModal})
  }

  modalOtpOpen = () => {
    this.setState({
      modalOtp: true,
    });
  };

  modalOtpClose = () => {
    this.setState({
      modalOtp: false,
    },()=>{this.toggleSuccess()});
  };

  categoriesApi = () => {
    let token = getQuery()["token"]
    fetch(`${baseUrl}/v2/course/codingStore/categories`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({categoryList:this.state.categoryList.concat(res.data)})
        // this.setState({categoryList:res.data})
        console.log(this.state.categoryList)
      })
      .catch((err) => toast.error(err.message));
  };

  render() {
    const {
      modalOtp,
      isSuccess
    } = this.state;

    return (
      <div className="Home">
        <Navigation />
        <AffiliateHeader 
          showFooter={this.state.showFooter} 
          scrollToRegister={this.modalOtpOpen} 
        />
        <AffiliateDescription />
        <AffiliateWhy />
        <Information text={this.state.englishPara} />
        <BuyCourses />
        <CodingPartners />
        <Information text={this.state.hindiPara} />
        <CodingNews />
        <FAQs />
        <ContactUs />
        <Footer />
       
        {modalOtp ? (
          <ShowcaseCourses isSuccess={isSuccess} submitNoApi={() => this.submitApi('no')} submitApi={this.submitApi} modalOtpClose={this.modalOtpClose} />
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default withRouter(Home);
