import React, { Component } from "react";
import ClassplusLogo from "../images/classplusLogo.svg";
import { Link } from "react-router-dom";

class Navigation extends Component {
  render() {
    return (
      <div className="navigation">
        <div className="navigation__logo">
          <Link to="/">
            <img
              src={ClassplusLogo}
              alt="Classplus"
              className="navigation__logo--img"
            />
          </Link>
        </div>
      </div>
    );
  }
}

export default Navigation;
