import React, { Component } from 'react';

class ContactUs extends Component {
    render(){
        return(
            <div className="ContactUs">
                <div className="ContactUs__Header">Contact Us</div>
                <div className="ContactUs__Para">For any queries, please contact us at <span className="darkColor">classplus.coding@classplus.co</span> or call us at 
                <span className="darkColor"> +91-8588030934</span> or <span className="darkColor">+91-9352649308</span></div>
            </div>
        )
    }
}

export default ContactUs;
