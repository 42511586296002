import React,{useState} from "react";

function FAQs({ modalOpen,scrollToRegister }) {

  const [showMore, handleMore] = useState(false);

  const showMoreFAQ = () =>{
    if(showMore){
      handleMore(false);
    }else{
      handleMore(true);
    }
  }

  return (
    <div className="faqContainer">
      <div className="faqContainer--heading">Frequently Asked Questions</div>
      <div className="faq">
        <input id="faq-a" type="checkbox" />
        <label for="faq-a">
          <div className="faq-heading">
            <div>
              <div>1.</div>
              <div>How do I earn through the Coding Store?</div>
            </div>
            <div className="faq-arrow"></div>
          </div>

          <div className="faq-text">
            Publish Coding Store on your Application and earn 30% commission on every sale.
            <br />
          </div>
        </label>
        <input id="faq-b" type="checkbox" />
        <label for="faq-b">
          <div className="faq-heading">
            <div>
              <div>2.</div>
              <div>How does this help me grow?</div>
            </div>
            <div className="faq-arrow"></div>
          </div>

          <div className="faq-text">
            Coding Store by Classplus gives you an opportunity to make available high quality coding courses by top brands to your students on your App. This will attract more students on your APP and differentiate yourself from other coaching institutes.
            <br />
          </div>
        </label>
        <input id="faq-c" type="checkbox" />
        <label for="faq-c">
          <div className="faq-heading">
            <div>
              <div>3.</div>
              <div>When can I see the courses?</div>
            </div>
            <div className="faq-arrow"></div>
          </div>

          <div className="faq-text">
            {/* Click on{" "}
            <span onClick={modalOpen} style={{ color: "#009AE0" }}>
              Sign up
            </span>{" "} */}
            Coding Store by Classplus will be launched soon. You have to register to get access to the Coding Store in your application.
          </div>
        </label>
        {showMore ? <div>
        <input id="faq-d" type="checkbox" />
        <label for="faq-d">
          <div className="faq-heading">
            <div>
              <div>4.</div>
              <div>
              How can students choose the right course?
              </div>
            </div>
            <div className="faq-arrow"></div>
          </div>

          <div className="faq-text">
          Course information would be listed along the course on your Application. Students can also reach us and our counsellors will help the students in choosing the right course.
          </div>
        </label>
        <input id="faq-e" type="checkbox" />
        <label for="faq-e">
          <div className="faq-heading">
            <div>
              <div>5.</div>
              <div>
                {" "}
                Who would students be learning from? 
              </div>
            </div>
            <div className="faq-arrow"></div>
          </div>

          <div className="faq-text">
          Students would be learning from the best faculties of our partners (Coding Ninjas, Coding Element and others). The faculties will train the students 1:1 with proper feedback mechanisms during the classes.
          </div>
        </label>
        <input id="settings" type="checkbox" />
        </div> 
        
        :""}
        
      </div>
      {/* <div className="moreFAQ">
        <button className="moreFAQ__btn" onClick={showMoreFAQ}>{!showMore ?"See More FAQs":"See Less FAQs"}</button>
      </div> */}
    </div>
  );
}

export default FAQs;
