import React, { Component } from "react";
import coin from "../images/coding-store/Icon3.png";
import diamond from "../images/coding-store/Icon.png";
import mic from "../images/coding-store/Icon2.png";

class AffiliateWhy extends Component {
  render() {
    return (
      <div className="AffiliateWhy">
        <div className="AffiliateWhy__Header">
          <p>How will The Coding Store by Classplus benefit you?</p>
        </div>
        <div className="AffiliateWhy__SubHeader">Get Coding Store by Classplus on your app</div>
        <div className="AffiliateWhy__Div">
          <div className="AffiliateWhy__Div--Col">
            <img src={diamond} alt="file" />
            <div className="AffiliateWhy__Div--Col_Header">Build your coaching brand and attract more students</div>
            <p className="AffiliateWhy__Div--Col_Para2">
              Get access to top quality coding courses by best International and National partners for your students
            </p>
          </div>
          <div className="AffiliateWhy__Div--Col">
            <img src={mic} alt="click" />
            <div className="AffiliateWhy__Div--Col_Header">Make your students ready to work with best companies</div>
            <p className="AffiliateWhy__Div--Col_Para2">
              Give your students a chance to work at Google, Facebook, Microsoft, Amazon, Uber etc.
            </p>
          </div>
          <div className="AffiliateWhy__Div--Col">
            <img src={coin} alt="coins" />
            <div className="AffiliateWhy__Div--Col_Header">Increase revenue</div>
            <p className="AffiliateWhy__Div--Col_Para2">
              Provide quality courses and earn more
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default AffiliateWhy;
